import { graphql } from "gatsby"
import React from "react"
import ContactFooter from "../components/ContactForm/ContactFooter"
import useParser from "../components/HelperComponents/useParser"
import SEO from "../components/seo"
import mainStyle, {
  mainTextStyle,
  sectionStyle,
} from "../components/styles/mainStyle"

const BasicPage = ({ data }) => {
  const { titel, content, description } = data.prismicBasic.data
  const { main_color_two } = data.prismicBasicInfo.data

  const parsedHTML = useParser(content.html)

  return (
    <>
      <SEO title={titel.text} description={description.text} />

      <main
        className="borderradius-div-bottom"
        style={{ ...mainStyle, backgroundColor: main_color_two }}
      >
        <div style={sectionStyle}>
          <div
            dangerouslySetInnerHTML={{ __html: titel.html }}
            style={{ textAlign: "center", padding: "50px 0 0" }}
          />
          <section
            id="uitgebreide-text"
            style={mainTextStyle}
            children={parsedHTML}
          />
        </div>
      </main>

      <ContactFooter />
    </>
  )
}

export default BasicPage

export const basicQuery = graphql`
  query basicBySlug($uid: String!) {
    prismicBasic(uid: { eq: $uid }) {
      uid
      data {
        titel {
          html
          text
        }
        content {
          html
        }
        description {
          text
        }
      }
    }
    prismicBasicInfo {
      data {
        main_color_two
      }
    }
  }
`
